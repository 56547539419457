// export const HTTP_ADDRESS = 'https://localhost:3900/api/'
// export const HTTP_ADDRESS = 'https://vaccine-management.onrender.com/api/'
export const HTTP_ADDRESS = 'https://api.babyshieldapi.co.in/api/'
// export const HTTP_ADDRESS = 'https://46.28.44.211:3900/api/'
// export const HTTP_IMAGE = 'https://localhost:3900/'
export const HTTP_IMAGE = HTTP_ADDRESS.split('api/')[0];


export const customStyles = {
    headCells: {
        style: {
           backgroundColor : 'lightgray',
        },
    }
};

export let Menu = [
    {
      displayName: "Dashboard",
      link: "/",
      icon: "",
    },
    {
      displayName: "Users",
      link: "/user",
      icon: "",
    },
    {
      displayName: "Childs",
      link: "/child",
      icon: "",
    },
    {
      displayName: "Vaccine",
      link: "/vaccine",
      icon: "",
    },
    {
      displayName: "Doctor",
      link: "/doctor",
      icon: "",
    },
  ];
