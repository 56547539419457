import React, { useState } from 'react';
import './Login.css'
import { useForm } from 'react-hook-form';
import { login } from '../../Services/AuthService';
import { col, row } from 'react-bootstrap';

// function Login() {
//     const { register, handleSubmit, watch, formState: { errors } } = useForm();
//     const onSubmit = data => {
//         login(data);
//     };
//     return (
//         <>
//             <div className='row login m-0'>
//                 <div className='col-11 col-md-8 mx-auto login_section'>
//                     <div className='row'>
//                         <div className='col login_section_left p-4 d-md-flex d-none'>
//                             <div>
//                                 <h1 className='display-6'>Welcome Back!</h1>
//                                 <p className='mt-4'>We welcome you at our official website.
//                                     Here you can find everything about Ecommerce. Our focus is to make site easy with cost-effective, efficient and robust tech solutions that can make a huge impact.</p>
//                             </div>
//                         </div>
//                         <div className='col p-5 py-2'>
//                             <form className='py-3' onSubmit={handleSubmit(onSubmit)}>
//                                 <h3 className='display-6 mb-5'>Login to your Account</h3>
//                                 <label className='input_lable'>Username</label>
//                                 <input type="text" className='input_group w-100 p-2' placeholder='username' {...register("username", { required: true })} />
//                                 {errors.username && <span className='Error_msg'>Username is required</span>}

//                                 <label className='input_lable mt-3'>Password</label>
//                                 <input type="password" className='input_group w-100 p-2' placeholder='123' {...register("password", { required: true })} />
//                                 {errors.password && <span className='Error_msg'>Password is required</span>}

//                                 <button className='same_theme_button px-5 py-3 mt-4'>Login</button>
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//         </>
//     )
// }


// export default Login


function Login() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [showPassword, setShowPassword] = useState(false);

    const onSubmit = data => {
        login(data);
    };



    return (
        <>
            <div className='row login m-0'>
                <div className='col-11 col-md-8 mx-auto login_section'>
                    <div className='row'>
                        <div className='col login_section_left p-4 d-md-flex d-none'>
                            <div>
                                <h1 className='display-6'>Welcome Back!</h1>
                                <p className='mt-4'>We welcome you at our official website.
                                    Here you can find everything about Ecommerce. Our focus is to make site easy with cost-effective, efficient and robust tech solutions that can make a huge impact.</p>
                            </div>
                        </div>
                        <div className='col p-5 py-2'>
                            <form className='py-3' onSubmit={handleSubmit(onSubmit)}>
                                <h3 className='display-6 mb-5'>Login to your Account</h3>
                                <label className='input_lable'>Username</label>
                                <input type="text" className='input_group w-100 p-2' placeholder='username' {...register("username", { required: true })} />
                                {errors.username && <span className='Error_msg'>Username is required</span>}

                                <label className='input_lable mt-3'>Password</label>
                                <div className='w-100 py-2' style={{ position: 'relative' }}>
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        className='w-100 p-2 outline'
                                        placeholder='123'
                                        {...register("password", { required: true })}
                                    />
                                    <span className="login-icons">
                                        {showPassword ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                fill="currentColor"
                                                className="bi bi-eye-slash-fill"
                                                viewBox="0 0 16 16"
                                                onClick={() => setShowPassword(false)}
                                            >
                                                <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
                                                <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                fill="currentColor"
                                                className="bi bi-eye-fill"
                                                viewBox="0 0 16 16"
                                                onClick={() => setShowPassword(true)}
                                            >
                                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />

                                            </svg>
                                        )}
                                    </span>
                                </div>
                                {errors.password && <span className='Error_msg'>Password is required</span>}

                                <button className='same_theme_button px-5 py-3 mt-4'>Login</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
