import moment from 'moment';
import React from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { HOC } from '../../Components/HOC/HOC';
import { customStyles } from '../../Constant/Constant';
import { deleteUser } from '../../Redux/Actions/UserAction';
import Swal from 'sweetalert2';

function User() {
    const dispatch = useDispatch();
    let state = useSelector(state => state.user.user);

    const handleButtonClick = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to delete this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteUser(id));
            }
        });
        // console.log(`Button with id ${id} clicked!`);
        
    };

    const tableActions = (id) => {
        return (
            <button className="btn btn-danger" onClick={() => handleButtonClick(id)}>
                Delete
            </button>
        );
    };

    const columns = [
        {
            name: 'No.',
            selector: (row, i) => i + 1,
            width: '60px'
        },
        {
            name: 'Name',
            selector: row => row.enname,
        },
        {
            name: 'Surname',
            selector: row => row.ensurname,
        },
        {
            name: 'Gender',
            selector: row => row.engender,
        },
        {
            name: 'Relation',
            selector: row => row.enrelation,
        },
        {
            name: 'Email',
            selector: row => row.email,
            width: '200px'
        },
        {
            name: 'Mobile no.',
            selector: row => row.mobileNo,
        },
        {
            name: 'BirthDate',
            selector: row => moment(row.birthDate).format('ll'),
        },
        {
            name: 'City',
            selector: row => row.encity,
        },
        {
            name: 'State',
            selector: row => row.enstate,
        },
        {
            name: 'Country',
            selector: row => row.encountry,
        },
        {
            name: 'Landmark',
            selector: row => row.enlandmark,
        },
        {
            name: 'Action',
            cell: row => tableActions(row?._id) // Changed selector to cell
        },
    ];

    return (
        <>
            <div className='d-flex justify-content-between mb-3'>
                <h3>USERS</h3>
            </div>
            <div className="row bg-white">
                <DataTable columns={columns} customStyles={customStyles} data={state} pagination />
            </div>
        </>
    );
}

export default HOC(User);
